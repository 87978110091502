import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const questionnaireTemplateService = {
  update(claimGroupId, questionnaire_email_template) { 
    return fetch(`/api/claim_groups/${claimGroupId}/questionnaire_email_templates/${questionnaire_email_template.id}`, {
      ...request.put,
      body: JSON.stringify({ questionnaire_email_template }),
    });
  },
}

export default questionnaireTemplateService;