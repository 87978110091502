import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams, useLocation } from 'react-router-dom';
import { Breadcrumb, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Loading } from '@rd-web-markets/shared/dist/util';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import NoClaimsPage from '@components/shared/claim/NoClaimsPage';
import claimScheduleService from '@rd-web-markets/shared/dist/services/claim_schedule.service';
import ClaimGroupReportTemplatesControls from '@components/shared/claim_group/ClaimGroupReportTemplatesControls';
import claimTemplateCategoriesService from '@services/claim_template_categories.service';
import ClaimGroupClaimList from '@rd-web-markets/market/dist/claim_group/overview/ClaimGroupClaimList';
import claimGroupImportTechnicalTemplatesService from '@services/claim/claim_group_import_technical_templates.service';
import importCostTemplateService from '@services/claim/import_cost_template.service';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import CompleteClaim from '@components/shared/claim/complete/CompleteClaim';
import changeSetsService from '@services/claim/change_sets.service';
import ClaimGroupTechnicalManagerQAReviews from '@components/shared/managerReviews/ClaimGroupTechnicalManagerQAReviews';
import claimChangeSetsService from '@services/claim_groups/claim_change_sets_service';
import claimGroupReportTemplateService from '@rd-web-markets/shared/dist/services/claim_group_report_templates.service';
import claimGroupTechnicalNarrativeService from '@services/claim_groups/claim_group_technical_narrative.service';
import ClaimGroupEngagementTeam from '@components/shared/claim_group/ClaimGroupEngagementTeam';
import IntroEmailPage from '@components/shared/claim_group_emails/IntroEmailPage';
import ScheduleEmail from '@components/shared/claim_group_emails/ScheduleEmail';
import ClaimGroupClaimScheduleSection from '@components/shared/claim_group/ClaimGroupClaimScheduleSection';
import ProjectSummarySection from '@components/shared/claim_group/ProjectSummarySection';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import ClaimGroupSidebar from './ClaimGroupSidebar';
import { useTranslation } from 'react-i18next';
import ClaimGroupClientReview from '@components/shared/managerReviews/ClaimGroupClientReview';
import { SHOW_SIDE_BAR_IN_CLAIM_GROUP_OVERVIEW } from '@rd-web-markets/market/dist/constants';
import { CompanyService } from '@rd-web-markets/shared/dist/services/company.service';

const ClaimGroupOverviewPage = ({ handleToaster, accountType }) => {
  const { search } = useLocation();
  const clientContact = new URLSearchParams(search).get('client-contact-section');
  const currentClaimCostId = new URLSearchParams(search).get('current-claim-cost-id');
  const { company_id, claim_group_id, claim_group_step } = useParams();
  const [company, setCompany, claimGroup, setClaimGroup, resetCompanyAndClaimGroup, updateCompany] = CompanyService.useGetCompanyAndClaimGroup(company_id, claim_group_id);
  const [loading, setLoading] = useState(false);
  const [isPending, setisPending] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(true)
  const [isTechnicalNarrativePending, setIsTechnicalNarrativePending] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    if (clientContact) {
      setHasScrolled(false);
    }
  }, [clientContact, setHasScrolled]);

  useEffect(() => {
    if (isPending) {
      const intervalId = window.setInterval(() => {
        claimGroupService.get(claimGroup.id)
          .then(claimGroup => {
            setClaimGroup({ ...claimGroup });
            if(claimGroup.claims.every(function(claim) {
              return claim.claim_import_cost_template_infos[claim.claim_import_cost_template_infos.length - 1].import_status !== 'pending'
            })){
              setisPending(false);
              handleToaster('Import Completed!');
            }
          })
          .catch(err => {
            dispatch(handleError(err.description));
          });
      }, 3000);
      return () => window.clearInterval(intervalId);
    }
  }, [isPending, claimGroup, dispatch, handleToaster]);

  useEffect(() => {
    if (isTechnicalNarrativePending) {
      const intervalId = window.setInterval(() => {
        claimGroupService.get(claimGroup.id)
          .then(claimGroup => {
            setClaimGroup({ ...claimGroup });
            if(claimGroup?.technical_narrative_upload_statuses.slice(-1)[0].import_status !== 'pending') {
              setIsTechnicalNarrativePending(false);
              handleToaster('Import Completed!');
            }
          })
          .catch(err => {
            dispatch(handleError(err.description));
          });
      }, 5000);
      return () => window.clearInterval(intervalId);
    }
  }, [isTechnicalNarrativePending, claimGroup, dispatch, handleToaster]);


  const sendClientCostEmail = async (claim) => {
    setLoading(true);
    try {
      await claimScheduleService.sendClientCostMail(claim.claim_schedule.id);
      handleToaster('Email Successfully Send');
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleImportCostTemplate = async (event, claim, projectId) => {
    let fd = new FormData();
    fd.append('file', event.target.files[0]);
    setLoading(true);
    try {
      setisPending(true);
      await importCostTemplateService.create(claim.id, fd, projectId);
      const response = await claimGroupService.get(claimGroup.id);
      setClaimGroup({...response});
    } catch(error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  };

  const handleDeleteReportTemplate = async (targetTemplate, templateType) => {
    if(window.confirm('Are you sure you want to remove this template ?')){
      setLoading(true);
      let templateTypeToRemove = templateType ? templateType.split('template_type=')[1] : null;

      try {
        //pass true as the last param to archive
        await claimGroupReportTemplateService.update(claimGroup.id, targetTemplate, templateTypeToRemove, true);
        if(templateType){ // Italy and UK have different report template types
          const reportTemplateTypes = {
            technical: 'report_template_project_specific',
            inexpert_declaration_dossier: 'report_template_inexpert_declaration_dossier',
            expert_declaration_dossier: 'report_template_expert_declaration_dossier',
            aif: 'report_template_AIF',
            rnd: 'report_template_rnd'
          };


          setClaimGroup({
            ...claimGroup,
            [reportTemplateTypes[templateTypeToRemove]]: null
          });
        }else{
          setClaimGroup({
            ...claimGroup,
            report_template: null
          })
        }
      } catch(error) {
        dispatch(handleError(error));
      }
      setLoading(false);
    }
  };

  const createChangeSetsForAllClaimsInClaimGroup = async (object, object_type) => {
    setLoading(true);
    try {
      await claimChangeSetsService.create(claimGroup.id)
      await resetCompanyAndClaimGroup();
    } catch(error) {
      dispatch(handleError(error))
    }
    setLoading(false);
  }

  const createChangeSet = changeSetsService.useCreateChangeSet(resetCompanyAndClaimGroup, setLoading)
  const createChangeSetForClient = changeSetsService.useCreateChangeSetForClient(resetCompanyAndClaimGroup, setLoading, handleToaster)
  const finalizeChangeSet = changeSetsService.useFinalizeChangeSet(resetCompanyAndClaimGroup, setLoading)
  const approveChangeSet = changeSetsService.useApproveChangeSet(resetCompanyAndClaimGroup, setLoading)
  const rejectChangeSet = changeSetsService.useRejectChangeSet(resetCompanyAndClaimGroup, setLoading)

  const onClaimGroupUpdate = async () => {
    await resetCompanyAndClaimGroup();
  };

  const handleAymingContactChange = async (event) => {
    const value = event.target.checked;
    const claimScheduleCopy = { ...claimGroup.claim_schedule }
    claimScheduleCopy[event.target.name] = value
    try {
      const updatedClaimSchedule = await claimScheduleService.update(claimGroup.id, claimScheduleCopy);
      setClaimGroup({...claimGroup, claim_schedule: updatedClaimSchedule});
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  const handleProccessDate = async (e) => {
    const claimSchedule = {...claimGroup.claim_schedule}
    try {
      const response = await claimScheduleService.update(claimGroup.id, {...claimSchedule,
        [e.target.name]: e.target.value
      });
      setClaimGroup({...claimGroup, claim_schedule: response});
    } catch (error) {
      dispatch(handleError(error));
    }
  }

  const handleClaimSchedule = async e => {
    setLoading(true);
    const claimSchedule = {...claimGroup.claim_schedule }
    try {
      claimSchedule[e.target.name] = e.target.value;
      const response = await claimScheduleService.update(claimGroup.id, claimSchedule);
      setClaimGroup({...claimGroup, claim_schedule: response});
      handleToaster('Claim Schedule Saved!');
    } catch(error) {
      dispatch(handleError(error))
    } finally {
      setLoading(false);
    }
  }

  const sendIntroductionMail = async () => {
    setLoading(true);
    try {
      await claimScheduleService.sendIntroductionMail(claimGroup.claim_schedule.id);
      handleToaster('Email Successfully Send');
    } catch (error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  }

  const sendIcsEventMail = async () => {
    setLoading(true);
    try {
      await claimScheduleService.sendIcsEventMail(claimGroup.claim_schedule.id);
      handleToaster('Email Successfully Send');
    } catch (error) {
      dispatch(handleError(error));
    }
    setLoading(false);
  }

  const clientContactRef = element => {
    if(!hasScrolled){
      window.location.hash = '';
      window.location.hash = '#client-contact-engagement-page';
      setHasScrolled(true);
    }
  }

  const getCurrentStep = () => {
    switch (claim_group_step) {
      case 'engagement_team':
        return (<>
          <div ref={clientContactRef} id="client-contact-engagement-page">
            <ClaimGroupEngagementTeam
              company={company}
              claimGroup={claimGroup}
              handleAymingContactChange={handleAymingContactChange}
              setClaimGroup={setClaimGroup}
              onClaimScheduleClientContactParticipationChange={resetCompanyAndClaimGroup}
              loading={loading}
              customTitle={'Contacts & Kick-Off Meeting Organization'}
            />
            </div>
        </>)
        case 'intro_email':
        return (<>
          <IntroEmailPage
            claimGroup={claimGroup}
            sendIntroductionMail={sendIntroductionMail}
            onClaimGroupUpdate={onClaimGroupUpdate}
            setClaimGroup={setClaimGroup}
            loading={loading}
          />
        </>)
      case 'claim_schedule':
        return (
            <ClaimGroupClaimScheduleSection
              company={company}
              setCompany={setCompany}
              updateCompany={updateCompany}
              handleClaimSchedule={handleClaimSchedule}
              claimGroup={claimGroup}
              loading={loading}
              handleProccessDate={handleProccessDate}
              handleToaster={handleToaster}
            />)
        case 'schedule_email':
          return (<>
            <ScheduleEmail
              claimGroup={claimGroup}
              sendIcsEventMail={sendIcsEventMail}
              onClaimScheduleClientContactParticipationChange={resetCompanyAndClaimGroup}
              onClaimGroupUpdate={onClaimGroupUpdate}
              setClaimGroup={setClaimGroup}
              loading={loading}
            />
          </>)
        case 'report_template':
          return (
            <ClaimGroupReportTemplatesControls handleDeleteReportTemplate={handleDeleteReportTemplate} claimGroup={claimGroup} />
          )
        case 'manager_review':
          return (
            <ClaimGroupTechnicalManagerQAReviews
                claimGroup={claimGroup}
                loading={loading}
                company={company}
                createChangeSet={createChangeSet}
                approveChangeSet={approveChangeSet}
                rejectChangeSet={rejectChangeSet}
                finalizeChangeSet={finalizeChangeSet}
                resetCompanyAndClaimGroup={resetCompanyAndClaimGroup}
              />
          )
        case 'client_review':
          return (
            <ClaimGroupClientReview
              claimGroup={claimGroup}
              setClaimGroup={setClaimGroup}
              loading={loading}
              company={company}
              createChangeSetForClient={createChangeSetForClient}
              approveChangeSet={approveChangeSet}
              rejectChangeSet={rejectChangeSet}
              finalizeChangeSet={finalizeChangeSet}
              resetCompanyAndClaimGroup={resetCompanyAndClaimGroup}
            />
          )
        case 'complete_claim':
          return (
            <CompleteClaim claimGroup={claimGroup} />
          )
      case 'claim_costs':
        return (
          <ClaimGroupClaimList
            handleToaster={handleToaster}
            accountType={accountType}
            loading={loading}
            claimGroup={claimGroup}
            setClaimGroup={setClaimGroup}
            company={company}
            currentClaimCostId={currentClaimCostId}
            onClaimGroupUpdate={onClaimGroupUpdate}
            sendClientCostEmail={sendClientCostEmail}
            handleImportCostTemplate={handleImportCostTemplate}
            createChangeSet={createChangeSet}
            finalizeChangeSet={finalizeChangeSet}
            approveChangeSet={approveChangeSet}
            rejectChangeSet={rejectChangeSet}
            createChangeSetsForAllClaimsInClaimGroup={createChangeSetsForAllClaimsInClaimGroup}
          />
        )
      case 'project_summary':
        return (
          <ProjectSummarySection claimGroup={claimGroup} />
        )
      default:
        return (<>
          <div ref={clientContactRef} id="client-contact-engagement-page">
            <ClaimGroupEngagementTeam
              company={company}
              claimGroup={claimGroup}
              handleAymingContactChange={handleAymingContactChange}
              setClaimGroup={setClaimGroup}
              onClaimGroupUpdate={onClaimGroupUpdate}
              onClaimScheduleClientContactParticipationChange={resetCompanyAndClaimGroup}
              sendIntroductionMail={sendIntroductionMail}
              loading={loading}
              customTitle={'Contacts & Kick-Off Meeting Organization'}
            />
            </div>
        </>)
    }
  }

  if(!company || !claimGroup) return <Loading />;

  if(company && !claimGroup) return <NoClaimsPage company={company} accountType={accountType}/>;

  return (
    <>
      <ClaimGroupSidebar showMenuItems={SHOW_SIDE_BAR_IN_CLAIM_GROUP_OVERVIEW} claimGroup={claimGroup} highlightedMenuItem={claim_group_step} />
      <BreadcrumbsPortal>
        <Breadcrumb>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/` }}>
            {t('home')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/` }}>
            {t('companies')}
          </Breadcrumb.Item>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: `/${accountType}/companies/${company.id}/${claimGroup.id}/master` }}>{company.name}</Breadcrumb.Item>
          <Breadcrumb.Item active>{claimGroup.name.replace(claimGroup.company.name, '').trim()}</Breadcrumb.Item>
        </Breadcrumb>
      </BreadcrumbsPortal>

      <div className='d-flex'>
        <h1 className="text-info mb-4 font-weight-bold">{company.name}</h1>
        { company.risk == 'high' &&
          <div className='ml-2' size='lg'>
            <OverlayTrigger placement="right" overlay={<Tooltip>"Company SIC code is "High risk"</Tooltip>}>
              <span className='material-icons' style={{color: '#DCA90E'}}>warning</span>
            </OverlayTrigger>
          </div>
        }
      </div>

      {getCurrentStep()}
    </>
  )
}

export default ClaimGroupOverviewPage
