import { Loading } from '@rd-web-markets/shared/dist/util';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Alert, Card } from 'react-bootstrap';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { useDispatch } from 'react-redux';
import claimGroupService from '@rd-web-markets/shared/dist/services/claim_group.service';
import ClaimGroupBreadcrumbLinks from '@components/shared/claim_group/edit/ClaimGroupBreadcrumbLinks';
import ClaimGroupEditNavigation from '@components/shared/claim_group/edit/ClaimGroupEditNavigation';
import ClaimGroupSidebar from './ClaimGroupSidebar';
import QuestionaireTemplateParticipants from './ClaimGroupQuestionaireTemplate/QuestionaireTemplateParticipants';
import QuestionaireTemplateBody from './ClaimGroupQuestionaireTemplate/QuestionaireTemplateBody';
import QuestionaireTemplateHistory from './ClaimGroupQuestionaireTemplate/QuestionaireTemplateHistory';

const ClaimGroupEditQuestionaireTemplate = ({accountType}) => {
  const { claimGroupId } = useParams();
  const [claimGroup, setClaimGroup] = useState(null);
  const dispatch = useDispatch();
  const [isUnderReview, setisUnderReview] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const claimGroup = await claimGroupService.get(claimGroupId);
        setClaimGroup(claimGroup);
        if (claimGroup?.active_change_set) {
          setisUnderReview(true);
        };
      } catch (error) {
        dispatch(handleError(error));
      }
    };
    getData();
  }, [claimGroupId, dispatch]);

  if(!claimGroup) return <Loading />

  return (
    <>
      { isUnderReview && 
        <Alert key='warning' variant='warning'>
          Claim info can not be edited after manager or client review has started  
        </Alert> 
      }
      <ClaimGroupSidebar claimGroup={claimGroup} highlightedMenuItem='edit/general' />
      <ClaimGroupBreadcrumbLinks claimGroup={claimGroup}/>
      <Card>
        <Card.Header>
          <ClaimGroupEditNavigation accountType={accountType} page={'questionaire'} claimGroup={claimGroup}/>
        </Card.Header>
        <Card.Body>
          <QuestionaireTemplateParticipants claimGroup={claimGroup} setClaimGroup={setClaimGroup}/>
          <hr />
          <QuestionaireTemplateBody claimGroup={claimGroup} setClaimGroup={setClaimGroup}/>
          <QuestionaireTemplateHistory claimGroup={claimGroup} setClaimGroup={setClaimGroup} />
        </Card.Body>
      </Card>
    </>
  )
}

export default ClaimGroupEditQuestionaireTemplate