import PastSentEmailList from '@components/shared/claim/emailPreview/PastSentEmailList';
import React from 'react';
import { Row, Col } from 'react-bootstrap';


const QuestionaireTemplateHistory = ({claimGroup, setClaimGroup}) => {
  return (
    <Row className='align-items-start'>
      <Col md={{ span: 4, offset: 4 }}>
        <PastSentEmailList claimGroup={claimGroup} pastSentTemplatesList={claimGroup.questionaire_email_templates} />
      </Col>
    </Row>
  )
}

export default QuestionaireTemplateHistory