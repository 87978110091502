import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap';
import ChangeHighlightedImmutableUnderReviewControl from '@rd-web-markets/shared/dist/util/reviewEditControls/ChangeHighlightedImmutableUnderReviewControl';
import { apiKey, getDefaultConfig } from '@rd-web-markets/shared/dist/util/tinyMceConfig'
import { useTranslation } from 'react-i18next';
import { setLoading } from '@rd-web-markets/shared/dist/store/features/uiSlice';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import claimGroupQuestionnaireTemplateService from '@rd-web-markets/shared/dist/services/claim_groups/claim_group_questionnaire_template.service';
import { Loading } from '@rd-web-markets/shared/dist/util';
import questionnaireTemplateService from '@services/claim_groups/questionnaire_template.service';

const QuestionaireTemplateBody = ({claimGroup, setClaimGroup}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setActiveTemplate(claimGroup.active_questionaire_email_template);
  }, [claimGroup.id])

  const handleBodyChange = (content, field) => {
    setActiveTemplate({
      ...activeTemplate,
      [field]: content,
    });
  };

  const handleChange = (e) => {
    const value =
    e.target.type === 'checkbox'
      ? e.target.checked
      : e.target.value;

    setActiveTemplate({
      ...activeTemplate,
      [e.target.name]: value,
    });

  };

  const handleSubmit = async () => {
    const updatedTemplate = await questionnaireTemplateService.update(claimGroup.id, activeTemplate);
    setActiveTemplate(updatedTemplate);
  };

  const sendQuestionnaireEmail = async () => {
    try {
      setLoading(true);
      await claimGroupQuestionnaireTemplateService.create(claimGroup);
    } catch (e) {
      dispatch(handleError(e));
    } finally {
      setLoading(false)
    }
  }

  const getBodyName = () => {
    switch(activeTemplate.language) {
      case 'en':
        return 'en_body';
      case 'fr':
        return 'fr_body';
      default:
        return 'be_body';
    }
  }

  if(!activeTemplate) return <Loading />

  return (
    <>
      <Row>
        <Col md={9}>
          <p>Email body</p>
        </Col>
        <Col md={3}>
          <Form.Control
            type="text"
            as='select'
            name='language'
            onChange={handleChange}
            onBlur={handleSubmit}
            value={activeTemplate.language}
            label='Language'
          >
            <option value='en'>EN</option>
            <option value='fr'>FR</option>
            <option value='be'>BE</option>
          </Form.Control>
        </Col>
      </Row>
      <Form.Group as={Row} className="pt-2">
        <Col md={12}>
          <ChangeHighlightedImmutableUnderReviewControl
            as={Editor}
            apiKey={apiKey}
            onEditorChange={(e) => handleBodyChange(e, getBodyName())}
            object={activeTemplate}
            onBlur={handleSubmit}
            name={getBodyName()}
            init={getDefaultConfig()}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className='pt-2'>
        <Form.Label column="md" md={1}>
          {t('Send Type')}
        </Form.Label>
        <Col md={3}>
          <Form.Control
            name="send_frequency"
            as='select'
            size="md"
            type="text"
            onChange={handleChange}
            onBlur={handleSubmit}
            value={activeTemplate.send_frequency}
          >
            <option value='automatic'>Automatic</option>
            <option value='manual'>Manual</option>
          </Form.Control>
        </Col>
        <Form.Label column="md" md={2}>
          {t('date_of_month_sent')}
        </Form.Label>
        <Col md={3}>
          <Form.Control
            name="date_of_month_sent"
            value={activeTemplate.date_of_month_sent}
            size="md"
            type="text"
            onChange={handleChange}
            onBlur={handleSubmit}
          ></Form.Control>
        </Col>
        <Col md={2}>
          <Form.Check
            type="checkbox"
            name="is_enabled"
            checked={activeTemplate.is_enabled}
            value={activeTemplate.is_enabled}
            onChange={handleChange}
            onBlur={handleSubmit}
            label="Enable"
          />
        </Col>
      </Form.Group>
      <Row>
        <Col md={{span: 3, offset: 9}}>
          <Button varinat='info' onClick={() => sendQuestionnaireEmail()}>
            Manualy Send Questionnaire
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default QuestionaireTemplateBody