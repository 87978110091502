export var reportTemplateInitial = {
  inexpert_declaration_dossier: {
    id: 0,
    name: '',
    report_template_categories: [{
      title: 'The Company',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Table of Investments Text',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Regulatory reference',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Stakeholders',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Eligible investments',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Annex A annexed to Law no. 232',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Annex B annexed to Law no. 232',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Time scope',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Use of the tax credit',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Interconnection',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Documentary requirements',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'List of regulatory references',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Conclusions',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Annex',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Years of Investments',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }]
  },
  expert_declaration_dossier: {
    id: 0,
    name: '',
    report_template_categories: [{
      title: 'The Company',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Regulatory reference',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Stakeholders',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Eligible Investments',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Annex A annexed to Law no. 232',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Annex B annexed to Law no. 232',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Time scope',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Use of the tax credit',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Interconnection',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Documentary requirements',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'List of regulatory references',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Determination of Benefit',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Conclusions',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Annex',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Years of Investments',
      content: 'text here...',
      exclude_from_report: false,
      should_apply_styles: true
    }]
  },
  project_specific: {
    id: 0,
    name: '',
    report_template_categories: [{
      title: 'Page 2 - Logo Page',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Classification of the asset',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Examination of the mandatory requirements',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Examination of possession of additional requirements',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Check list 2 - goods annex A - mandatory and additional requirements (par. 11.1 circ. AE 4/E)',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Brief description of the machine and its technical characteristics',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Annex 2-B documentation',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Investment',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }, {
      title: 'Representation of information and material flows',
      content: '',
      exclude_from_report: false,
      should_apply_styles: true
    }]
  }
};