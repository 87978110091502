function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
import { useCallback, useEffect, useState } from 'react';
import { buildQueryString, parseQueryString } from '../../services/service';
var useSortRows = function useSortRows(_ref) {
  var tableHeaders = _ref.tableHeaders,
    tableRows = _ref.tableRows,
    setTableRows = _ref.setTableRows;
  var sortRows = useCallback(function (columnIndex, direction) {
    var defaultvalueFnForSortAndFilter = function defaultvalueFnForSortAndFilter(row) {
      return row.columns[columnIndex];
    };
    var valueFnForSortAndFilter = tableHeaders[columnIndex].valueFnForSortAndFilter || defaultvalueFnForSortAndFilter;
    var nonSortableRows = tableRows.filter(function (r) {
      var _r$opts;
      return ((_r$opts = r.opts) === null || _r$opts === void 0 ? void 0 : _r$opts.sort) === false;
    });
    var sortableRows = tableRows.filter(function (r) {
      var _r$opts2;
      return ((_r$opts2 = r.opts) === null || _r$opts2 === void 0 ? void 0 : _r$opts2.sort) !== false;
    });
    var sortedRows = _toConsumableArray(sortableRows).sort(function (a, b) {
      var _ref2 = [valueFnForSortAndFilter(a), valueFnForSortAndFilter(b)],
        valueA = _ref2[0],
        valueB = _ref2[1];
      if (valueA > valueB) {
        return direction === 'asc' ? 1 : -1;
      }
      if (direction === 'asc') {
        return valueA === valueB ? 0 : -1;
      } else {
        return valueA === valueB ? 0 : -1;
      }
    });

    // For now those rows are only below the sortable ones.
    // In the future we can add additional functionality here if needed.
    sortedRows.push.apply(sortedRows, _toConsumableArray(nonSortableRows));
    setTableRows(sortedRows);
  }, [setTableRows, tableHeaders, tableRows]);
  return sortRows;
};
function resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex, textFilters, setTextFilters) {
  tableHeaders.forEach(function (h) {
    return h.order = null;
  });
  var tableHeaderFilberBy = tableHeaders[columnIndex].filterBy;
  textFilters[tableHeaderFilberBy] = text;
  setTextFilters(_objectSpread({}, textFilters));
  setTableHeaders(_toConsumableArray(tableHeaders));
}

/**
 * Builds a query string from the text filters object, and calls onFilter with the current tableHeaders and the query string.
 * Good for backend filtering.
 * @param {*} param0 
 * @returns 
 */
var useBuildQueryForBackendFiltering = function useBuildQueryForBackendFiltering(_ref3) {
  var tableHeaders = _ref3.tableHeaders,
    setTableHeaders = _ref3.setTableHeaders,
    onFilter = _ref3.onFilter,
    textFilters = _ref3.textFilters,
    setTextFilters = _ref3.setTextFilters;
  var buildQueryForBackendFiltering = useCallback(function (text, columnIndex) {
    resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex, textFilters, setTextFilters);
    var queries = Object.entries(textFilters).reduce(function (acc, _ref4) {
      var _ref5 = _slicedToArray(_ref4, 2),
        key = _ref5[0],
        value = _ref5[1];
      acc[key] = value;
      return acc;
    }, {});
    var queryString = buildQueryString(queries);
    onFilter(tableHeaders, queryString);
  }, [tableHeaders, setTableHeaders, textFilters, setTextFilters, onFilter]);
  return buildQueryForBackendFiltering;
};
var useFilterRowsByText = function useFilterRowsByText(_ref6) {
  var tableHeaders = _ref6.tableHeaders,
    setTableHeaders = _ref6.setTableHeaders,
    rows = _ref6.rows,
    setTableRows = _ref6.setTableRows,
    onFilter = _ref6.onFilter,
    textFilters = _ref6.textFilters,
    setTextFilters = _ref6.setTextFilters;
  // Sorting is related to filtering
  var filterRowsByText = useCallback(function (text, columnIndex) {
    var _filteredRows;
    resetOrderAndSetFilterText(tableHeaders, setTableHeaders, text, columnIndex, textFilters, setTextFilters);
    var nonFilterableRows = rows.filter(function (r) {
      var _r$opts3;
      return ((_r$opts3 = r.opts) === null || _r$opts3 === void 0 ? void 0 : _r$opts3.filterableByText) === false;
    });
    var filterableRows = rows.filter(function (r) {
      var _r$opts4;
      return ((_r$opts4 = r.opts) === null || _r$opts4 === void 0 ? void 0 : _r$opts4.filterableByText) !== false;
    });
    var filteredRows = filterableRows;
    var _iterator = _createForOfIteratorHelper(tableHeaders.entries()),
      _step;
    try {
      var _loop = function _loop() {
        var _step$value = _slicedToArray(_step.value, 2),
          index = _step$value[0],
          header = _step$value[1];
        if (header.filterableByText) {
          var filterText = (textFilters[header.filterBy] || '').toLowerCase();
          filteredRows = filteredRows.filter(function (r) {
            var defaultvalueFnForSortAndFilter = function defaultvalueFnForSortAndFilter(row) {
              return row.columns[index];
            };
            var valueFnForSortAndFilter = header.valueFnForSortAndFilter || defaultvalueFnForSortAndFilter;
            var columnText = (valueFnForSortAndFilter(r) || '').toLowerCase();
            return columnText.includes(filterText);
          });
        }
      };
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        _loop();
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
    (_filteredRows = filteredRows).push.apply(_filteredRows, _toConsumableArray(nonFilterableRows));
    setTableRows(filteredRows);
  }, [rows, setTableHeaders, setTableRows, setTextFilters, tableHeaders, textFilters]);
  return filterRowsByText;
};

/**
 * If we pass onFilter, then we get the build query string method, which results in calling onFilter with the table headers and
 * a query string produced from the current text filter values. This is good for backend sorting.
 * Otherwise we use local sorting.
 * @param {*} param0 
 * @returns 
 */
var useTextFilteringMethod = function useTextFilteringMethod(_ref7) {
  var tableHeaders = _ref7.tableHeaders,
    setTableHeaders = _ref7.setTableHeaders,
    rows = _ref7.rows,
    setTableRows = _ref7.setTableRows,
    onFilter = _ref7.onFilter,
    textFilters = _ref7.textFilters,
    setTextFilters = _ref7.setTextFilters;
  var buildQueryForBackendFiltering = useBuildQueryForBackendFiltering({
    tableHeaders: tableHeaders,
    setTableHeaders: setTableHeaders,
    onFilter: onFilter,
    textFilters: textFilters,
    setTextFilters: setTextFilters
  });
  var filterRowsByText = useFilterRowsByText({
    tableHeaders: tableHeaders,
    setTableHeaders: setTableHeaders,
    rows: rows,
    setTableRows: setTableRows,
    onFilter: onFilter,
    textFilters: textFilters,
    setTextFilters: setTextFilters
  });
  var filteringMethod = onFilter ? buildQueryForBackendFiltering : filterRowsByText;
  return filteringMethod;
};
var useTextFiltering = function useTextFiltering(textFilterQueryStringParamName) {
  var _useState = useState({}),
    _useState2 = _slicedToArray(_useState, 2),
    textFilters = _useState2[0],
    setTextFilters = _useState2[1];

  // setting the filter text here is required for when the page is loaded with a query string already set
  // Sets a default filterBy value for the headers. This is required for the textFilters state to work properly.
  // In case we want backend filtering - then the filterBy should be set outside of this method.
  // This filterBy is a default value ot make the local filtering work.
  var mapHeaders = useCallback(function (headers) {
    return _toConsumableArray(headers).map(function (h, i) {
      return _objectSpread(_objectSpread({}, h), {}, {
        order: h.order || null,
        _filterText: textFilters[h.filterBy] || '',
        filterBy: h.filterBy || "".concat(h.text, "-").concat(i)
      });
    });
  }, [textFilters]);

  // On the first render of the table - read the text filters from the query string parameters.
  // Only on the first render, to prevent a flashing text error. In future renders - the filters
  // will be stored and retrieved from the textFilters state variable.
  useEffect(function () {
    var jsonQuery = parseQueryString();
    var filtersFromQueryString = {};
    if (textFilterQueryStringParamName && jsonQuery[textFilterQueryStringParamName]) {
      // for each param in the query - e.g. query=business_unit%3Dsome_bu_name&&project_name%3Dsome_project_name
      // it will save the following values-
      // business_unit: 'some_bu_name',
      // project_name: 'some_project_name'
      for (var key in jsonQuery[textFilterQueryStringParamName]) {
        filtersFromQueryString[key] = jsonQuery[textFilterQueryStringParamName][key];
      }
    }
    setTextFilters(_objectSpread({}, filtersFromQueryString));
  }, [textFilterQueryStringParamName]);
  return [textFilters, setTextFilters, mapHeaders];
};
var tableMethods = {
  useSortRows: useSortRows,
  useFilterRowsByText: useFilterRowsByText,
  // useResetOrderAndSetFilterText,
  useBuildQueryForBackendFiltering: useBuildQueryForBackendFiltering,
  useTextFiltering: useTextFiltering,
  useTextFilteringMethod: useTextFilteringMethod
};
export default tableMethods;