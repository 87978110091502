export default function sortObjectsByProperty(arr, property) {
  return arr.sort(function (a, b) {
    var valueA = a[property].toLowerCase();
    var valueB = b[property].toLowerCase();
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  });
}