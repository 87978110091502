export var CLAIM_PROJECT_OTHER_FIELDS = [{
  title: 'RO1 Cby means of CNC (Computer Numerical Control) and/or PLC (Programmable Logic Controller)'
}, {
  title: 'RO2 Interconnection to factory computer systems with remote loading of instructions and/or part programs'
}, {
  title: 'RO3 Automated integration with the factory logistics system or with the supply network and/or with other machines in the production cycle'
}, {
  title: 'RO4 The interface between man and machine simple and intuitive'
}, {
  title: 'RO5 Requirements to the latest parameters of safety, health and hygiene at work'
}, {
  title: 'RU1 Systems of remote maintenance and/ or remote diagnosis and / or remote control'
}, {
  title: 'RU2 Continuous turf'
}, {
  title: 'RU3 Integration features'
}];