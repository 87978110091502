import React from 'react';
import { Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ClaimGroupEditNavigation = ({page, claimGroup}) => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.auth.user);

  return (
    <Nav variant="tabs">
      <Nav.Item>
        <Nav.Link as={Link} active={page==='general'}
        to={`/${user.account_type}/claim_groups/${claimGroup.id}/edit/general`}>
          {t('general')}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link as={Link} active={page==='questionaire'}
        to={`/${user.account_type}/claim_groups/${claimGroup.id}/edit/questionaire`}>
          {t('new_project_questionaire')}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  )
}

export default ClaimGroupEditNavigation
