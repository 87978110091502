import React, { useEffect, useState } from 'react'
import { Breadcrumb, Card } from 'react-bootstrap';
import { Link, useParams, useHistory } from 'react-router-dom'
import { CompanyService } from '@services/company.service';
import { useDispatch } from 'react-redux';
import { handleError } from '@rd-web-markets/shared/dist/store/features/alertSlice';
import { fetchAndSetCompany } from 'src/effects/company.effects';
import { claimTemplateService } from '@services/claim_template.service';
import questionnairesService from '@services/questionnaires.service';
import BreadcrumbsPortal from '@rd-web-markets/shared/dist/util/BreadcrumbsPortal';
import SidebarPortal from '@rd-web-markets/shared/dist/util/SidebarPortal';
import { useTranslation } from 'react-i18next';
import { CompanyEditPageContent } from '@rd-web-markets/market/dist/company';
import CompanyMarketRelatedFields from '@rd-web-markets/market/dist/company/CompanyMarketRelatedFields';

const CompanyEditPage = ({accountType, handleToaster}) => {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [claimTemplates, setClaimTemplates] = useState([]);
  const { t } = useTranslation();

  const [questionnaires] = questionnairesService.useQuestionnaires();

  useEffect(() => {
    fetchAndSetCompany(id, setCompany, dispatch, handleError)

    claimTemplateService.get()
      .then(setClaimTemplates)
      .catch(error => dispatch(handleError(error)))
  }, [id, dispatch])

  if(!company || !questionnaires) return <div>Loading</div>

  const handleSubmit = async company => {
    setLoading(true);
    company.claims_attributes = company.claims;
    try {
      await CompanyService.update(company);
      setLoading(false);
      history.replace(`/${accountType}/companies/${company.id}/master`);
    } catch(error) {
      dispatch(handleError(error));
      setLoading(false);
    }
  }

  return <>
    <SidebarPortal headerItem={{link: `companies/${company.id}/master`, text: company.name}} />
    <BreadcrumbsPortal>
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}`}}>{t('home')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies`}}>{t('companies')}</Breadcrumb.Item>
        <Breadcrumb.Item linkAs={Link} linkProps={{to: `/${accountType}/companies/${company.id}/master`}}>{company.name}</Breadcrumb.Item>
        <Breadcrumb.Item active>{t('edit')}</Breadcrumb.Item>
      </Breadcrumb>
    </BreadcrumbsPortal>

    <CompanyEditPageContent
      claimTemplates={claimTemplates}
      company={company}
      setCompany={setCompany}
      handleSubmit={handleSubmit}
      loading={loading}
      handleToaster={handleToaster}
      setLoading={setLoading}
      questionnaires={questionnaires}
      CompanyMarketRelatedFields={CompanyMarketRelatedFields}
    />
  </>;
}

export default CompanyEditPage
