import React from 'react'
import { Nav, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ClaimGroupNavigation = ({company, claimGroupId, accountType}) => {
  const user = useSelector((state) => state.auth.user);
  const { t } = useTranslation();

  return (
    <Nav
      variant="tabs"
      defaultActiveKey={Number(claimGroupId)}
    >
      {company.claim_groups.map((claimGroup) => {
        return (
          <Nav.Item key={claimGroup.id}>
            <Nav.Link as={Link} to={`/${accountType}/companies/${company.id}/${claimGroup.id}/master`} eventKey={claimGroup.id}>
              {claimGroup.name} { claimGroup.is_locked ?
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip>
                      {claimGroup.is_locked && 'Claim Group is locked'}
                    </Tooltip>
                  }
                >
                  <span className="material-icons">lock</span>
                </OverlayTrigger> : null
              }
            </Nav.Link>
          </Nav.Item>
        );
      })}
      <Link className='h5 ml-auto my-auto' to={`/${user.account_type}/claim_groups/${claimGroupId}/project_overview`}>+ {t('project')}</Link>
    </Nav>
  )
}

export default ClaimGroupNavigation
