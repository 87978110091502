import React from 'react'
import { Row, Col, Button } from 'react-bootstrap';
import QuestionaireParticipantsList from './QuestionaireParticipantsList';

const QuestionaireTemplateParticipants = ({claimGroup, setClaimGroup}) => {
  return (
    <>
      <Row>
        <Col md={10}>
          <h3>Email Recipients</h3>
        </Col>
        <Col md={2}>
          <Button varinat='info'>
            Add Email Recipient
          </Button>
        </Col>
      </Row>
      <hr />
      <QuestionaireParticipantsList template={claimGroup.questionaire_email_template}/>
    </>
  )
}

export default QuestionaireTemplateParticipants